.remedy-card {
    padding: 15px;
    width: 445.5px;
    height: 224px;
    border: 1px solid #C1CCDD;
    background: #FFFFFF;
    border-radius: 10px;
}
.remedy-card-prescription {
    padding: 15px;
    width: 520px;
    height: 148px;
    border: 1px solid #C1CCDD;
    background: #FFFFFF;
    border-radius: 10px;
}

.remedy-image {
    border: 1px solid #C1CCDD;
    border-radius: 10px;
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.remedy-labels {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    line-height: 110%;
}

.remedy-name {
    font-size: 14px;
    margin: 0;
}

.remedy-subname {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 110%;
}

.remedy-price {
    font-size: 14px;
    font-weight: bold;
}

.remedy-code {
    background: #FFFFFF;
    border: 1px solid #F9F9FF;
    box-sizing: border-box;
    border-radius: 30px;
    display: flex;
    padding: 5px 5px 5px 15px;
    justify-content: center;
    width: 248.5px;
    height: 32px;
}

.remedy-code-value {
    font-weight: bold;
    font-size: 20px;
    line-height: 110%;
}

.remedy-info-div {
    background: #DFF8FA;
    border-radius: 10px;
    padding: 10px;
}

.remedy-list-div {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 20px;
    height: 768px;
    overflow: auto;
}
