.my-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    height: 224px;
    border: 1px solid #C1CCDD;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 10px 0px;
}

.my-input-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    position: static;
    height: 56px;
    background: #FFFFFF;
    border: 1px solid #E7EBF1;
    box-shadow: 12px 12px 40px rgba(19, 46, 89, 0.1);
    border-radius: 20px;
    width: 100%;
}

.my-button {
    position: relative;
    color: #30D4E3;
    padding: 12px 20px;
    width: 135px;
    height: 56px;
    background: #132E59;
    box-shadow: 8px 8px 20px rgba(19, 46, 89, 0.2);
    border-radius: 30px;
    flex: none;
    margin: 0px 10px;
}

.my-button:disabled {
    background: #858585;
    color: #FFFFFF;
    position: relative;
}

.filter-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 5px 5px 15px;
    height: 35px;
    background: #E7EBF1;
    border-radius: 30px;
    margin: 20px 0px;
}

.my-chip-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    height: 25px;
    left: calc(50% - 63px/2 - 90px);
    top: 0px;
    border: 1px solid #99ABC6;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 0px 10px;
    display: flex;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 15px;
    padding: 5px 10px;
}

.sucess-buble {
    width: 6px;
    height: 6px;
    left: calc(50% - 6px/2);
    top: calc(50% - 6px/2);
    margin-right: 8px;
    background: #20D6B7;
    border-radius: 50%;
}

.hidden {
    background: rgba(0, 0, 0, 0);
}

.my-row {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #C1CCDD;
    border-radius: 20px;
}

.lds-ellipsis {
    display: inline-block;
    top: -10px;
    left: 30px;
    position: absolute;
    width: 80px;
    height: 80px;
  }
  .lds-ellipsis div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #30D4E3;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
  
